export const FILTERS = [
  'design',
  'research',
  'leadership',
  'communication',
  'data',
  'software',
  'hardware',
  'maths',
];
export const FILTERNAMES = {
  design: 'Design',
  research: 'Research',
  leadership: 'Leadership',
  communication: 'Communication',
  data: 'Data Analysis',
  software: 'Software',
  hardware: 'Hardware',
  maths: 'Mathematics',
};
