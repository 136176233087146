export const contactInfo = {
  phone: '+1 425-524-6671',
  email: 'bm3027(at)columbia.edu or bma(at)colgate.edu',
  addr: [
    { label: 'Name', value: 'Brian Ma' },
    { label: 'Line 1', value: '70 Morningside' },
    { label: 'Line 2', value: 'F1554 Wien Hall Columbia Student Mail' },
    { label: 'City-State', value: 'New York, NY' },
    { label: 'Zip', value: '10027-7236' },
  ],
};
