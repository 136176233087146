export const coursework = [
  {
  type: '',
  code: 'COMS 6998',
  semester: 'Fall',
  year: '2021',
  title: 'Advanced Web Design Studio',
  description:
    'A studio class in which students design and build prototypes for emerging technologies.',
  institution: 'Columbia University',
  tags: [],
  relevance: ['software', 'design', 'communication', 'research']
  },
  {
    type: '',
    code: 'COMS 4731',
    semester: 'Fall',
    year: '2021',
    title: 'Computer Vision I: First Principles',
    description:
      'Introductory course in computer vision. Topics include image formation and optics, image sensing, binary images, image processing and filtering, edge extraction and boundary detection, region growing and segmentation, pattern classification methods, brightness and reflectance, shape from shading and photometric stereo, texture, binocular stereo, optical flow and motion, 2D and 3D object representation, object recognition, vision systems and applications.',
    institution: 'Columbia University',
    tags: ['math', 'apmath'],
    relevance: ['software', 'hardware', 'maths']
  },
  {
    type: '',
    code: 'STAT 4001',
    semester: 'Fall',
    year: '2021',
    title: 'Intro to Probability and Statistics',
    description:
      'A calculus-based tour of the fundamentals of probability theory and statistical inference. Probability models, random variables, useful distributions, conditioning, expectations, law of large numbers, central limit theorem, point and confidence interval estimation, hypothesis tests, linear regression.',
    institution: 'Columbia University',
    tags: ['math', 'apmath'],
    relevance: ['maths', 'software', 'hardware']
  },
  {
    type: '',
    code: 'COMS 4995',
    semester: 'Summer',
    year: '2021',
    title: 'C++ for C Programmers',
    description:
      'This course is a survey & analysis of C++ language from the C programmer’s perspective, focusing on how C++ features are implemented and to not just learn to use C++, but to understand it.  Topics include additional features on top of C, Object-Oriented Programming in C++ (Polymorphism, Multiple & virtual inheritance, I/O stream hierarchy), Generic Programming (Containers, iterators, algorithms, function objects and lambda), and RAII paradigm (Smart pointers).',
    institution: 'Columbia University',
    tags: ['cs'],
    relevance: ['software', 'hardware']
  },
  {
    type: '',
    code: 'CSEE 4119',
    semester: 'Summer',
    year: '2021',
    title: 'Computer Networks',
    description:
      'Introduction to computer networks and the technical foundations of the Internet, including applications, protocols, local area networks, algorithms for routing and congestion control, security, elementary performance evaluation. Several written and programming assignments required.',
    institution: 'Columbia University',
    tags: ['cs', 'math', 'apmath'],
    relevance: ['software', 'hardware', 'maths']
  },
  {
    type: '',
    code: 'COMS 6998',
    semester: 'Spring',
    year: '2021',
    title: 'Human Computer Interaction',
    description:
      'This course is a graduate-level seminar in human–computer interaction (HCI), which is a cross-disciplinary field that studies (1) what computers are used for, (2) how people interact with computers, and (3) how either of those should change in the future. We will be reading, presenting, and discussing research papers that cover both the foundations and the frontiers of HCI. The papers represent many research areas within HCI: ubiquitous computing, mobile health, interaction techniques, social computing, mixed reality, accessibility, policy, and more. We will discuss both the papers and the research process behind them — both the substance and soul of HCI research. Students will practice giving research presentations and will complete an original HCI research project as part of the course. Our weekly meetings will focus on presentations, discussion, and project feedback.',
    institution: 'Columbia University',
    tags: ['cs', 'design'],
    relevance: ['software', 'hardware', 'design', 'communication', 'research'],
  },
  {
    type: '',
    code: 'CSEE 3827',
    semester: 'Spring',
    year: '2021',
    title: 'Fundamentals of Computer Systems',
    description:
      'Fundamentals of computer organization and digital logic. Boolean algebra, Karnaugh maps, basic gates and components, flipflops and latches, counters and state machines, basics of combinational and sequential digital design. Assembly language, instruction sets, ALU’s, single-cycle and multi-cycle processor design, introduction to pipelined processors, caches, and virtual memory.',
    institution: 'Columbia University',
    tags: ['math', 'cs', 'apmath'],
    relevance: ['software', 'maths', 'hardware'],
  },
  {
    type: '',
    code: 'COMS 4733',
    semester: 'Spring',
    year: '2021',
    title: 'Computational Aspects of Robotics',
    description:
      'Introduction to robotics from a computer science perspective. Topics include coordinate frames and kinematics, computer architectures for robotics, integration and use of sensors, world modeling systems, design and use of robotic programming languages, and applications of artificial intelligence for planning, assembly, and manipulation.',
    institution: 'Columbia University',
    tags: ['math', 'cs', 'apmath', 'physics'],
    relevance: ['software', 'maths', 'hardware'],
  },
  {
    type: '',
    code: 'COMS 4705',
    semester: 'Fall',
    year: '2020',
    title: 'Natural Language Processing',
    description:
      'Computational approaches to natural language generation and understanding. Topics include information extraction, summarization, machine translation, dialogue systems, and emotional speech. Particular attention is given to robust techniques that can handle understanding and generation for the large amounts of text on the Web or in other large corpora. Programming exercises in several of these areas.',
    institution: 'Columbia University',
    tags: ['math', 'cs', 'apmath'],
    relevance: ['software', 'maths'],
  },
  {
    type: '',
    code: 'COMS 4167',
    semester: 'Fall',
    year: '2020',
    title: 'Computer Animation',
    description:
      'Intensive introduction to computer animation, including: fundamental theory and algorithms for computer animation, keyframing, kinematic rigging, simulation, dynamics, free-form animation, behavioral/procedural animation, particle systems, post-production; small groups implement a significant animation project; advanced topics as time permits.',
    institution: 'Columbia University',
    tags: ['math', 'cs', 'physics', 'apmath', 'design'],
    relevance: ['software', 'maths', 'design'],
  },
  {
    type: '',
    code: 'COMS 4170',
    semester: 'Fall',
    year: '2020',
    title: 'User Interface Design',
    description:
      'Introduction to the theory and practice of computer user interface design, emphasizing the software design of graphical user interfaces. Topics include basic interaction devices and techniques, human factors, interaction styles, dialogue design, and software infrastructure. Design and programming projects are required.',
    institution: 'Columbia University',
    tags: ['cs', 'design'],
    relevance: ['software', 'hardware', 'design', 'communication', 'research'],
  },
  {
    type: '',
    code: 'COMS 4701',
    semester: 'Fall',
    year: '2020',
    title: 'Artificial Intelligence',
    description:
      'Provides a broad understanding of the basic techniques for building intelligent computer systems. Topics include state-space problem representations, problem reduction and and-or graphs, game playing and heuristic search, predicate calculus, and resolution theorem proving, AI systems and languages for knowledge representation, machine learning and concept formation and other topics such as natural language processing may be included as time permits. Examples of AI applications where these techniques are used will be discussed.',
    institution: 'Columbia University',
    tags: ['math', 'cs', 'apmath'],
    relevance: ['software', 'maths', 'hardware'],
  },
  {
    type: '',
    code: 'COMS 3157',
    semester: 'Fall',
    year: '2020',
    title: 'Advanced Programming',
    description:
      'Practical, hands-on introduction to programming techniques and tools for professional software construction, including learning how to write code to given specifications as well as document the results. Provides introductory overview of C and C++ in a UNIX environment, for students with Java background. Also introduces scripting languages (perl) and basic web programming. UNIX programming utilities are also covered. Lab required.',
    institution: 'Columbia University',
    tags: ['cs'],
    relevance: ['software', 'hardware'],
  },
  {
    type: '',
    code: 'PHYS 448',
    semester: 'Spring',
    year: '2020',
    title: 'Real-Time Non-Linear Dynamics and Chaos',
    description:
      'An introduction to the techniques and concepts used to analyze real-time dynamic models that involve nonlinear terms. Applications are emphasized and demonstrate the universality of chaotic solution behavior. This course is team-taught by members of the physics and mathematics departments.',
    institution: 'Colgate University',
    tags: ['math', 'cs', 'physics', 'ee', 'apmath'],
    relevance: ['software', 'maths', 'hardware'],
  },
  {
    type: '',
    code: 'COSC 290',
    semester: 'Spring',
    year: '2020',
    title: 'Discrete Structures',
    description:
      'Introduces discrete computational structures, methods, and concepts utilized throughout computer science. Topics may include types, relations, functions, equivalence and congruence relations, recursion, order relations, partially ordered sets, lattices, Boolean algebras, logic, semi-groups, monoids, morphisms, languages, graphs, trees, finite state machines, counting, and probability.',
    institution: 'Colgate University',
    tags: ['math', 'cs', 'apmath'],
    relevance: ['software', 'maths', 'hardware'],
  },
  {
    type: '',
    code: 'MATH 375',
    semester: 'Spring',
    year: '2020',
    title: 'Abstract Algebra I',
    description:
      'Abstract algebraic structures, rather than results specific to the usual number systems, are developed. Basic algebraic structures presented include groups, rings, integral domains, and fields.',
    institution: 'Colgate University',
    tags: ['math'],
    relevance: ['maths'],
  },
  {
    type: '',
    code: 'MATH 214',
    semester: 'Fall',
    year: '2019',
    title: 'Linear Algebra',
    description:
      'A study of sets of linear equations and their transformation properties over vector spaces. Topics include: systems of linear equations, matrices, determinants, vector spaces, linear transformations, eigenvalues, eigenvectors, and diagonalization.',
    institution: 'Colgate University',
    tags: ['cs', 'math', 'ee', 'physics', 'apmath'],
    relevance: ['data', 'software', 'maths', 'hardware'],
  },
  {
    type: '',
    code: 'PHYS 431',
    semester: 'Fall',
    year: '2019',
    title: 'Classical Mechanics',
    description:
      "A detailed study, using vector calculus, of important problems in the mechanics of particles and extended bodies including a derivation of Lagrange's and Hamilton's equations, and other advanced topics.",
    institution: 'Colgate University',
    tags: ['math', 'physics', 'apmath'],
    relevance: ['maths'],
  },
  {
    type: '',
    code: 'PHYS 433',
    semester: 'Fall',
    year: '2019',
    title: 'Thermodynamics and Statistical Mechanics',
    description:
      'An introduction to the physical concepts underlying the formalism of thermal physics. Emphasis is on the role and meaning of entropy in physical systems and processes. Topics include black body radiation, liquid helium, superconductivity, negative temperature, and the efficient use of energy.',
    institution: 'Colgate University',
    tags: ['math', 'ee', 'physics', 'apmath'],
    relevance: ['hardware', 'maths'],
  },
  {
    type: '',
    code: 'PHYS 336',
    semester: 'Spring',
    year: '2019',
    title: 'Electronics',
    description:
      'A comprehensive treatment of basic electronics. The course covers analog and digital electronics. The analog section includes DC and AC circuits, filters, diodes, transistors, and operational amplifiers. The digital section includes combinational and sequential logic, integrated circuits, and interfacing.',
    institution: 'Colgate University',
    tags: ['math', 'ee', 'physics', 'apmath', 'cs'],
    relevance: ['data', 'hardware', 'maths'],
  },
  {
    type: '',
    code: 'PHYS 334',
    semester: 'Spring',
    year: '2019',
    title: 'Intro Quantum Mechanics & Special Relativity',
    description:
      'Provides the mathematical and conceptual foundation to understand two important developments in modern physics: special relativity and quantum theory, concentrating on wave mechanics.',
    institution: 'Colgate University',
    tags: ['math', 'physics', 'apmath', 'ee'],
    relevance: ['hardware', 'maths'],
  },
  {
    type: '',
    code: 'PHYS 205',
    semester: 'Fall',
    year: '2018',
    title: 'Mathematical Methods in Physics',
    description:
      "A one-semester introduction to the mathematical methods of physics, with an emphasis on applications and how these methods are used to approach various problems. The course will cover topics such as ordinary differential equations, complex numbers and Euler's equation, linear systems, Fourier series, Fourier transforms, computational techniques, series expansions, cylindrical and spherical coordinate systems, vector differentiation, divergence and curl, integration methods and multi-dimensional integrals. These topics will be brought to bear on physical problems such as the damped and driven oscillator, coupled oscillators, electric dipoles, beat frequencies, electromagnetic waves and electrostatic boundary-value problems.",
    institution: 'Colgate University',
    tags: ['math', 'physics', 'ee', 'apmath'],
    relevance: ['research', 'data', 'hardware', 'maths'],
  },
  {
    type: '',
    code: 'MATH 377',
    semester: 'Fall',
    year: '2018',
    title: 'Real Analysis I',
    description:
      'Results from calculus, including limits, continuity, the derivative, and the Riemann integral, are given a rigorous treatment.',
    institution: 'Colgate University',
    tags: ['math'],
    relevance: ['maths'],
  },
  {
    type: '',
    code: 'MATH 260',
    semester: 'Fall',
    year: '2018',
    title: 'Computational Mathematics',
    description:
      'An exploration of computer manipulation and presentation of mathematical ideas. The computer allows mathematics to be explored, manipulated and connected to data. No background in programming is presumed. Students learn how numbers are stored and manipulated on the computer in order to do mathematics (from calculus to linear algebra to exploring patterns in the integers), how mathematical functions can be encoded and presented, and how data relates to functions. Induction and continuity are methods of proof relying on infinity, yet will be explored using the finite number cruncher called a computer. The results provide examples and intuition for further mathematical investigation. The required lab portion of the course allows students to implement these ideas in practice.',
    institution: 'Colgate University',
    tags: ['math', 'apmath', 'cs', 'ee', 'physics'],
    relevance: ['data', 'software', 'hardware', 'maths'],
  },
  {
    type: '',
    code: 'MATH 250',
    semester: 'Spring',
    year: '2018',
    title: 'Number Theory & Mathematical Reasoning',
    description:
      'Questions about the positive integers 1, 2, 3 . . . have fascinated people for thousands of years. The ancient Greeks noted the existence of right triangles with sides of integral length, corresponding to equations such as 32 + 42 = 52 and 52 + 122 = 132. Is there a way of describing all such “Pythagorean Triples”? As another example, 5 = 12 + 22, 13 = 22 + 32, 17 = 12 + 42, while none of the primes 7, 11, or 19 can be expressed as the sum of two squares. Is there a pattern? Does it continue forever? This course focuses on such equations as a means for introducing students to the spirit and methods of modern mathematics. The emphasis throughout is on developing the ability to construct logically sound mathematical arguments and communicate these arguments in writing.',
    institution: 'Colgate University',
    tags: ['math', 'cs'],
    relevance: ['data', 'software', 'maths'],
  },
  {
    type: '',
    code: 'PHYS 232',
    semester: 'Spring',
    year: '2018',
    title: 'Introduction to Mechanics',
    description:
      'A study of classical mechanics using astronomical themes. The principles of kinematics, dynamics, conservation laws, and gravitation are developed and used to understand the properties of astronomical objects such as planetary systems, binary stars, and galaxies. Treatment is more thorough than in PHYS 111. Differential and integral calculus and vector manipulation are used throughout. Two lectures, two recitation meetings, and one laboratory session per week.',
    institution: 'Colgate University',
    tags: ['math', 'physics'],
    relevance: ['research', 'data', 'hardware', 'maths'],
  },
  {
    type: '',
    code: 'COSC 102',
    semester: 'Fall',
    year: '2017',
    title: 'Intro to Computing II',
    description:
      'This course is a continuation of COSC 101 and is the first course in the major. It develops advanced programming topics such as abstract data types and algorithms and their analysis. Abstract data structures introduced include stacks, queues, lists, and binary trees; algorithms include search and sort procedures. The concepts of information hiding, data abstraction, and modular design are emphasized. Object-oriented programming is used throughout the course.',
    institution: 'Colgate University',
    tags: ['math', 'cs', 'ee'],
    relevance: ['software', 'hardware', 'maths'],
  },
  {
    type: '',
    code: 'MATH 1340',
    semester: 'Summer',
    year: '2015',
    title: 'Mathematics and Politics',
    description:
      'We apply mathematical reasoning to some problems arising in the social sciences. We discuss game theory and its applications to political and historical conflicts. Power indices are introduced and used to analyze some political institutions. The problem of finding a fair election procedure to choose among three or more alternatives is analyzed.',
    institution: 'Cornell University',
    tags: ['math', 'apmath'],
    relevance: ['research', 'maths'],
  },
];
