import React from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { GoMarkGithub } from "react-icons/go";

class HomePage extends React.Component {
  componentDidMount() {
    ReactGA.pageview("home");
  }
  handleExternalLink(text) {
    ReactGA.event({
      category: "External",
      action: text,
    });
  }
  render() {
    return (
      <Container>
        <br />
        <br />
        <Row>
          <Col sm={{ span: 2, offset: 2 }}>
            <Image
              src="me_drawn.png"
              roundedCircle
              fluid
              aria-label="A handdrawn portrait image of Brian Ma"
            />
          </Col>
          <Col sm={6} style={{ textAlign: "left" }}>
            <h2 style={{ display: "inline" }}>Hello There!</h2>
            <h2 style={{ color: "#FFFFFF", display: "inline" }}>
              {" "}
              General Kenobi!
            </h2>

            <p>
              I'm <strong>Brian</strong>! I am currently a UI Engineer at{" "}
              <strong>Netflix</strong> on the{" "}
              <strong>Globalization Team</strong>.
            </p>
            <p>
              I majored in <strong>Computer Science</strong> at{" "}
              <strong>Columbia University</strong> from 2020 to 2022 and majored
              in <strong>Physics</strong> and minored in{" "}
              <strong>Mathematics</strong> and
              <strong> Jewish Studies</strong> at{" "}
              <strong>Colgate University</strong>.
            </p>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col sm={{ span: 8, offset: 4 }} style={{ textAlign: "left" }}>
            <Button
              variant="secondary"
              href="https://www.linkedin.com/in/mashaoen/"
              target="_blank"
              onClick={() => this.handleExternalLink("Visited LinkedIn")}
              aria-label="Button to Brian's LinkedIn"
            >
              <span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaLinkedin />
                  <span style={{ margin: "0 3px" }}>LinkedIn</span>
                </div>
              </span>
            </Button>{" "}
            <Button
              variant="secondary"
              href="https://github.com/AlephFive"
              target="_blank"
              onClick={() => this.handleExternalLink("Visited Github")}
              aria-label="Button to Brian's GitHub"
            >
              <span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <GoMarkGithub />
                  <span style={{ margin: "0 3px" }}>GitHub</span>
                </div>
              </span>
            </Button>{" "}
            <Button
              variant="secondary"
              href="https://www.instagram.com/nothatbrian/"
              target="_blank"
              onClick={() => this.handleExternalLink("Visited Instagram")}
              aria-label="Button to Brian's Instagram"
            >
              <span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaInstagram />
                  <span style={{ margin: "0 3px" }}>Instagram</span>
                </div>
              </span>
            </Button>{" "}
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 8, offset: 4 }} style={{ textAlign: "left" }}>
            <br />
            <div>
              You can download my <strong>resume</strong> in{" "}
              <a
                href="/files/BrianShaoenMa_Resume_Research.pdf"
                target="_blank"
                download
              >
                PDF
              </a>{" "}
              or{" "}
              <a
                href="/files/BrianShaoenMa_Resume_Research.docx"
                target="_blank"
                download
              >
                Word document
              </a>{" "}
              formats.
            </div>
          </Col>
        </Row>
        <Row>
          <br />
        </Row>
        <Row>
          <Col sm={{ span: 8, offset: 2 }} style={{ textAlign: "left" }}>
            <Card>
              <Card.Body>
                <h5>Who am I?</h5>
                <p>
                  I'm a UI/UX Engineer who loves to solve user problems with
                  creative solutions inspired by my diverse interests as well as
                  my cultural and academic backgrounds.
                </p>
                <h5>What are my interests?</h5>
                <p>
                  My primary interests are Human-Computer Interaction, Graphic
                  Design, UI/UX Design, Software Engineering, Electrical
                  Engineering, Physics, Political Science, and Language
                  Learning.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HomePage;
